import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_SPONSOR, VUE_APP_PERK_KEY } = process.env;

export default class ShieldService {

  static async newCreateStep(userData, benefData, planData, servicesData, step) {
    try {

      let url;
      const jsonData = { userData, benefData, planData, servicesData, step, sponsor: VUE_APP_SPONSOR };

      if (servicesData.type == 'create') {
        url = '/step/newCreate';
      } else if (servicesData.type == 'update') {
        url = '/step/newUpdate';
      }

      const carroResponse = await axios.post(
        `${VUE_APP_SHIELD_URL}${url}`,
        jsonData
      );

      return carroResponse;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error en el servicio Stepers",
      };
    }
  }

  static async newGetStepAbandoned(stepId) {

    const shieldRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/step/newGetAbandoned/${stepId}`
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }

  static async getPlans(category, age, code) {

    var maxAge = age;

    try {
      var url = `${VUE_APP_SHIELD_URL}/accidente`
      var params = "";

      if (code) {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&category=${category}&age=${maxAge}&code=${code}`
      } else {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&category=${category}&age=${maxAge}`
      }
      const shieldRes = await axios.get(
        params
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getPeople(personID) {

    try {

      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/person/get/${personID}`,
        {
          headers: { perk_key: VUE_APP_PERK_KEY },
        }
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async createContactActiveCampain(userData, servicesData) {

    try {
      const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/activeCampain/createContact`,
        {
          userData,
          servicesData,
          sponsor: VUE_APP_SPONSOR,
        }
      );

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al crear el contacto",
      };
    }
  }

  static async updateContactActiveCampain(userData, planData, servicesData, index) {

    try {

      const shieldRes = await axios.put(`${VUE_APP_SHIELD_URL}/activeCampain/updateContact`,
        {
          userData,
          planData,
          servicesData,
          index,
          sponsor: VUE_APP_SPONSOR,
        }
      );

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }

  static async getContactActiveCampain(email) {

    try {

      const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/activeCampain/getContact/${email}`);

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }

  static async createInsurance(person, beneficiaries, plan, stepId) {

    person.planName = person.PlanName
    person.birthdate = person.dates

    var BenefData = [];
    if (beneficiaries) {
      beneficiaries.forEach((benef) => {
        BenefData.push({
          name: benef.name,
          surname: benef.surname,
          rut: benef.rut,
          parentesco: benef.tipo,
          porcent: benef.porcentaje,
          fechaNacimiento: '' + benef.day.id + '-' + benef.month.number + '-' + benef.year.name,
        });
      });
      person.BenefDataAP = BenefData;
    }

    const jsonData = { person, planId: plan._id, stepId: stepId };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/accidente/createWithRunWayPayment`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }


  // static async createInsurance(person, beneficiaries, plan, stepId) {

  //   person.planName = person.PlanName
  //   person.birthdate = person.dates

  //   var BenefData = [];
  //   if (beneficiaries) {
  //     beneficiaries.forEach((benef) => {
  //       BenefData.push({
  //         name: benef.name,
  //         surname: benef.surname,
  //         rut: benef.rut,
  //         parentesco: benef.tipo,
  //         fechaNacimiento: '' + benef.day + '-' + benef.month + '-' + benef.year,
  //       });
  //     });
  //     person.BenefData = BenefData;
  //   }

  //   const jsonData = { person, planId: plan[0]._id, stepId: stepId };

  //   const shieldRes = await axios.post(
  //     `${VUE_APP_SHIELD_URL}/accidente`,
  //     jsonData
  //   );
  //   if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
  //   else throw shieldRes.data;
  // }

//   static async createInsuranceWithToku(person, beneficiaries, plan, stepId) {

//     person.planName = person.PlanName
//     person.birthdate = person.dates

//     var BenefData = [];
//     if (beneficiaries) {
//       beneficiaries.forEach((benef) => {
//         BenefData.push({
//           name: benef.name,
//           surname: benef.surname,
//           rut: benef.rut,
//           parentesco: benef.tipo,
//           porcent: benef.porcent,
//         });
//       });
//       person.BenefData = BenefData;
//     }

//     var sponsorTokuRedirect = process.env.VUE_APP_SPONSOR_TOKU_REDIRECT

//     const jsonData = { person, planId: plan[0]._id, stepId: stepId, sponsorTokuRedirect: sponsorTokuRedirect };

//     const shieldRes = await axios.post(
//       `${VUE_APP_SHIELD_URL}/accidente/createWithToku`,
//       jsonData
//     );
//     if (shieldRes.data.status === "ok") return shieldRes.data.data;
//     else throw shieldRes.data;
//   }

}