<template>
  <v-container>
    <br>

    <NavBar :step="2"></NavBar>

    <Titulo></Titulo>

    <br>

    <br v-if="screen < 800">

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
            step="1">
            Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="4"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br>

      <v-stepper-items>
        <v-stepper-content step="3">
          <div>

            <v-form ref="form" v-model="valid">
              <br>
              <br>
              <div class="formData">

                <h3 class="text-center labelPlanes">
                  Ingresa a tus beneficiarios
                </h3>

                <h4 v-if="screen > 800" class="text-center labelSubPlanes">
                  Ingresar a tus beneficiarios aunque sean los legales ayuda a que si <br> se activa el seguro, podremos
                  más rápidamente apoyar a tu familia
                </h4>

                <h4 v-else class="text-center labelSubPlanes2">
                  Ingresar a tus beneficiarios aunque sean los legales ayuda a que si se activa el seguro, podremos más
                  rápidamente apoyar a tu familia
                </h4>

                <br>
                <br>
                <v-div fluid v-for="(benef, index) in benefData" :key="index" :class="StyleContendor"
                  style="justify-content:center; align-items:center">
                  <div class="d-flex  flex-md-row align-items-center">
                    <div class="mb-2 mb-md-0 mr-md-3" style="margin-right :6px; margin-bottom:15px">
                      <v-text-field :class="styleRut" v-model.lazy="benef.newRut" :rules="rutRules" maxlength="13"
                        label="RUT (sin punto y con guión)" required filled solo @input="changeRut(index)"
                        @change="change()">
                      </v-text-field>
                    </div>
                    <div class="mb-2 mb-md-0 mr-md-3" :class="StyleSpaceBotom">
                      <v-select :class="styleParentesco" :items="personType" v-model="benef.tipo" label="Parentesco"
                        :rules="parentescoRules" return-object required filled solo @change="change()">
                      </v-select>
                    </div>
                    <div class="circle-container">
                      <button class="circle-button" @click.prevent="deletedBeneficiary(index)">
                        <v-icon style="color:white">mdi-minus</v-icon>
                      </button>
                    </div>
                  </div>

                  <br>

                  <div class="div-dates">
                    <div style="margin-right: 6px; margin-bottom:15px">
                      <v-text-field :class="StyleField" v-model="benef.name" :rules="nameRules" label="Nombre" required
                        maxlength="51" filled solo @change="change()"></v-text-field>
                    </div>

                    <div style="margin-right: 6px; margin-bottom:15px">
                      <v-text-field :class="StyleField" v-model="benef.surname" :rules="surnameRules" label="Apellido"
                        maxlength="51" required filled solo @change="change()"></v-text-field>
                    </div>

                    <div style="margin-bottom: auto;">
                      <v-text-field :class="StylePorcent" type="text" v-model="benef.porcentaje"
                        :rules="percentageRules" label="" maxlength="3" required filled solo
                        @keydown="validateNumberInput($event)" @change="change()"></v-text-field>
                    </div>
                  </div>

                  <v-row>

                    <v-col>
                      <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="monthRules"
                        :items="months" v-model="benef.month" :loading="loadingMonths" v-on:change="getDays(index)"
                        item-text="name" label="Mes" return-object required filled solo @change="change()"></v-select>
                    </v-col>

                    <v-col>
                      <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="dayRules"
                        :items="benef.days" v-model="benef.day" :loading="loadingDays"
                        :disabled="loadingDays || benef.month == null" item-text="name" label="Día" return-object
                        required filled solo @change="setDate(index)">
                      </v-select>
                    </v-col>

                    <v-col>
                      <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="yearRules"
                        value="2023" :items="years" v-model="benef.year" item-text="name" label="Año" return-object
                        required filled solo @change="setDate(index)"></v-select>
                    </v-col>

                  </v-row>

                  <br>
                  <br>
                  <br v-if="screen < 800">

                </v-div>

              </div>

              <br> <br v-if="screen > 800">


              <div class="formData">
                <v-row style="justify-content:center; align-items:center">
                  <v-btn class="botonAgregar" style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600; color: white;
                    border-radius: 8px; margin-top: -6%; " elevation="0" text @click="addBeneficiary()">
                    Agregar Otro
                  </v-btn>

                </v-row>

              </div>
              <br><br>

              <div :class="StyleAlert" style="max-width: 60%; margin: auto;">
                <div v-if="totalPorcentaje === 100">
                  <p class="messageAlertPositive">Porcentaje completado</p>
                </div>
                <div v-else>
                  <p class="messageAlertNegative">{{ totalPorcentaje }}% debe ajustar el porcentaje</p>
                </div>
                <br>
              </div>

              <br><br>

              <div class="button-container">
                <v-btn style="background-color: #2D387C; color: white; border-radius: 12px; width: 300px; height: 50px;"
                  @click="validate()">
                  Continuar
                </v-btn>

                <br>
                <br>

                <v-btn text @click="back()">
                  Atrás
                </v-btn>

              </div>
            </v-form>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import RutUtils from "@/utils/rut.utils.js";

import Titulo from "./Titulo.vue"
import NavBar from "@/components/NavBar.vue";

import ShieldService from "../services/shield.service";
import agelist from "@/services/age-list.service.js";
import hijoList from "@/services/hijo-list.service.js";
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";

import swal from 'sweetalert';
import CryptoJS from 'crypto-js';

export default {
  name: "Step1",
  components: { Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    months: [],
    days: ["Selecciona el mes"],
    years: [],
    loadingDays: false,
    stepperColor: '#00968F',
    e1: 3,
    personType: [
      "Hijo(a)",
      "Pareja",
      "Cónyuge",
    ],
    styleParentesco: null,
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      address: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      hasAcode: null,
      stepId: null,
      old: null,
      day: null,
      month: null,
      year: null,
      dates: null,
      typePlan: null,
      PlanName: null,
      datesBenef: null,
      sonExist: false,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },
    benefData: [
      {
        tipo: null,
        edad: null,
        name: null,
        surname: null,
        rut: null,
        year: null,
        month: null,
        day: null,
        days: null,
        newRut: null,
        porcentaje: "100",
        date: null,
      }
    ],
    planData: {
      couponId: null,
      plan: null,
    },
    beneficiaries: [],
    plan: [],
    screen: window.screen.width,
    agelist: [],
    hijoList: [],
    valid: false,
    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
    edadRules: [
      (v) => !!v || "La edad es requerida",
    ],
    parentescoRules: [
      (v) => !!v || "El parentesco es requerido",
    ],
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => (v && v.length >= 11 && v.length <= 12) || "Rut no válido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    percentageRules: [
      (v) => /^\d+(\.\d{1,2})?$/.test(v) || 'Ingrese un porcentaje válido',
      (v) => parseFloat(v) > 0 || 'Ingrese un valor mayor que 0',
      (v) => parseFloat(v) >= 0 && parseFloat(v) <= 100 || '% entre 0 y 100'
    ],
    monthRules: [
      (v) => !!v || "El mes es requerido",
    ],
    dayRules: [
      (v) => !!v || "El día es requerido",
    ],
    yearRules: [
      (v) => !!v || "El año es requerido",
    ],
  }),
  async mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.getAgeList();
    this.getHijoList();

    const userDataAux = localStorage.getItem('userData');
    const servicesDataAux = localStorage.getItem('servicesData');
    const benefDataAux = localStorage.getItem('benefData');

    const query = this.$route.query;

    if (query.stepID) {
      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';
      this.planData = abandoned.planData;
      this.benefData = abandoned.benefData;

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const planDataLocal = JSON.stringify(this.planData);
      const benefDataLocal = JSON.stringify(this.benefData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
      const encryptedBenefDataLocal = CryptoJS.AES.encrypt(benefDataLocal, this.secretKey).toString();

      localStorage.setItem('userData', encryptedUserDataLocal);
      localStorage.setItem('servicesData', encryptedServicesDataLocal);
      localStorage.setItem('planData', encryptedPlanDataLocal);
      localStorage.setItem('benefData', encryptedBenefDataLocal);

      this.verifiRut();

    } else if (benefDataAux) {

      const bytesBenefData = CryptoJS.AES.decrypt(benefDataAux, this.secretKey);
      const decryptedDataBenef = bytesBenefData.toString(CryptoJS.enc.Utf8);
      this.benefData = JSON.parse(decryptedDataBenef);
      console.log("benefData: ", this.benefData);

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      console.log("userData: ", this.userData);

      this.verifiRut();

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      console.log("servicesData: ", this.servicesData);

    } else {
      const benefDataLocal = JSON.stringify(this.benefData);
      const encryptedBenefDataLocal = CryptoJS.AES.encrypt(benefDataLocal, this.secretKey).toString();
      localStorage.setItem('benefData', encryptedBenefDataLocal);

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      console.log("servicesData: ", this.servicesData);
    }

    if (this.screen > 800) {
      this.styleParentesco = 'labelParentesco'
      this.styleRut = 'labelRut'
      this.StyleField = "labelField"
      this.StylePorcent = "labelPorcent"
      this.StyleAlert = "labelAlert"
      this.StyleContendor = "contenedorDatos"
      this.StyleSpaceBotom = "SpaceBotom"
    } else {
      this.styleParentesco = 'labelParentesco2'
      this.styleRut = 'labelRut2'
      this.StyleField = "labelField2"
      this.StylePorcent = "labelPorcent2"
      this.StyleAlert = "labelAlert2"
      this.StyleSpaceBotom = "SpaceBotom2"
    }

    this.setArrays();

  },
  methods: {
    async setArrays() {

      this.months = await dateService.getMonths();
      this.years = await yearService.getYears();

      this.benefData.forEach((benef, index) => {
        if (benef.date) {
          this.getDaysMounted(index);
        }
      });
    },
    async getDays(index) {
      if (this.benefData[index].month) {
        this.loadingDays = true;
        this.benefData[index].day = null;
        this.benefData[index].days = await dateService.getDays(this.benefData[index].month.name);
        this.changeBisiesto(index);
        this.loadingDays = false;
      }
    },
    async getDaysMounted(index) {
      if (this.benefData[index].month) {
        this.loadingDays = true;
        this.benefData[index].days = await dateService.getDays(this.benefData[index].month.name);
        this.changeBisiesto(index);
        this.loadingDays = false;
      }
    },
    verifiRut() {
      const cleanRut = RutUtils.cleanRut(this.userData.rut);
      let isRutDuplicated = [false, -1];

      for (let idx = 0; idx < this.benefData.length; idx++) {
        const beneficiary = this.benefData[idx];
        if ( beneficiary.rut && cleanRut && RutUtils.cleanRut(beneficiary.rut) === cleanRut) {
          isRutDuplicated = [true, idx];
          break;
        }
      }

      if (isRutDuplicated[0]) {
        this.benefData[isRutDuplicated[1]].rut = null;
        this.benefData[isRutDuplicated[1]].newRut = null;
        swal({
          title: "Atención!",
          text: "Tu RUT no puede coincidir con el del contratante",
          icon: "info",
          button: "De acuerdo",
        });
      }
    },
    changeRut(index) {
      const cleanRut = RutUtils.cleanRut(this.benefData[index].newRut);
      const formattedRut = RutUtils.formatRut(this.benefData[index].newRut);

      const isRutDuplicated = this.benefData.some((beneficiary, idx) => {
        return idx !== index && RutUtils.cleanRut(beneficiary.rut) === cleanRut;
      });

      if (isRutDuplicated) {
        swal({
          title: "Atención!",
          text: "Tu RUT no puede coincidir con el de otro beneficiario!",
          icon: "info",
          button: "De acuerdo",
        });
      } else {
        this.benefData[index].rut = formattedRut;
        this.benefData[index].newRut = formattedRut;
      }

      if (this.userData.rut) {
        this.verifiRut();
      }

    },
    async changeBisiesto(index) {
      if (this.benefData[index].month && this.benefData[index].year && this.benefData[index].days && this.benefData[index].month.name == 'Febrero' && !this.isBisiesto(this.benefData[index].year.id) && this.benefData[index].days.length == 29) {
        this.benefData[index].date = null;
        this.benefData[index].days = await dateService.getDays(
          this.benefData[index].month.name, false
        );
        this.daysArray = this.benefData[index].days
      } else if (this.benefData[index].month && this.benefData[index].year && this.benefData[index].days && this.benefData[index].month.name == 'Febrero' && this.isBisiesto(this.benefData[index].year.id) && this.benefData[index].days.length == 28) {
        this.benefData[index].days = await dateService.getDays(
          this.benefData[index].month.name, true
        );
        this.daysArray = this.benefData[index].days
      } else {
        this.benefData[index].days = await dateService.getDays(
          this.benefData[index].month.name, null
        );
        this.daysArray = this.benefData[index].days
      }
    },
    isBisiesto(anio) {
      return (anio % 4 === 0 && anio % 100 !== 0) || (anio % 400 === 0);
    },
    setDate(index) {
      if (this.benefData[index].month && this.benefData[index].day && this.benefData[index].year) {
        this.benefData[index].date = '' + this.benefData[index].month.number + '-' + this.benefData[index].day.id + '-' + this.benefData[index].year.name;
      }
      this.changeBisiesto(index);
    },
    async change() {

      try {

        await ShieldService.newCreateStep(this.userData, this.benefData, null, this.servicesData, 'Beneficiarios');

      } catch (error) {
        console.log("Cart Fail");
      }
    },
    addBeneficiary() {
      if (this.benefData.length <= 6) {
        let beneficiarioAux = {
          tipo: null,
          edad: null,
          name: null,
          surname: null,
          rut: null,
          year: null,
          month: null,
          day: null,
          days: null,
          newRut: null,
          porcentaje: "100",
          date: null,
        }
        this.benefData.push(beneficiarioAux);
      } else {
        swal({
          title: "Atención!",
          text: "No puedes agregar mas beneficiarios!",
          icon: "info",
          button: "De acuerdo",
        });
      }
    },
    async deletedBeneficiary(index) {
      this.benefData.splice(index, 1);
    },
    async validate() {
      if (this.totalPorcentaje === 100 && this.validateBeneficiaryPercentages()) {
        if (this.screen > 800) {
          this.StylePorcent = 'labelPorcent';
        } else {
          this.StylePorcent = 'labelPorcent2';
        }
        if (this.$refs.form.validate()) {

          this.userData.sonExist = this.benefData.some(benef => benef.tipo === "Hijo(a)");
          this.userData.cantidadBenef = this.benefData.length;

          this.change();

          const userDataLocal = JSON.stringify(this.userData);
          const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
          localStorage.setItem('userData', encryptedUserDataLocal);

          const benefDataLocal = JSON.stringify(this.benefData);
          const encryptedBenefDataLocal = CryptoJS.AES.encrypt(benefDataLocal, this.secretKey).toString();
          localStorage.setItem('benefData', encryptedBenefDataLocal);

          if (this.activeCampaingUse == 'TRUE') {

            const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

            this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

            await ShieldService.updateContactActiveCampain(this.userData, null, this.servicesData, 5);

            const servicesDataLocal = JSON.stringify(this.servicesData);
            const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
            localStorage.setItem('servicesData', encryptedServicesDataLocal);

          }

          this.$router.push({ path: '/ap/datos-contratante' });
        }
      } else {
        if (this.screen > 800) {
          this.StylePorcent = 'labelPorcentRed';
        } else {
          this.StylePorcent = 'labelPorcent2Red';
        }
      }
    },
    validateBeneficiaryPercentages() {
      for (const benef of this.benefData) {
        if (!benef.porcentaje || parseFloat(benef.porcentaje) === 0 || parseFloat(benef.porcentaje) % 1 !== 0) {
          return false;
        }
      }
      return true;
    },
    validateNumberInput(event) {
      if (
        (event.keyCode >= 48 && event.keyCode <= 57) || // Números normales
        (event.keyCode >= 96 && event.keyCode <= 105) || // Números del teclado numérico
        event.keyCode === 8 || // Backspace
        event.keyCode === 46 || // Delete
        event.keyCode === 37 || // Flecha izquierda
        event.keyCode === 39 || // Flecha derecha
        event.keyCode === 9 // Tabulación
      ) {
        // Permite la acción predeterminada
      } else {
        event.preventDefault();
      }
    },
    async getAgeList() {
      this.loadingAgeList = true;
      this.agelist = await agelist.getAgeList();
      this.loadingAgeList = false;
    },
    async getHijoList() {
      this.loadingHijoList = true;
      this.hijoList = await hijoList.getHijoList();
      this.loadingHijoList = false;
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    back() {
      this.$router.push({ path: '/ap/planes' })
    }
  },
  watch: {
    totalPorcentaje(newValue) {
      if (newValue === 100 && this.validateBeneficiaryPercentages()) {
        if (this.screen > 800) {
          this.StylePorcent = 'labelPorcent';
        } else {
          this.StylePorcent = 'labelPorcent2';
        }
      } else {
        if (this.screen > 800) {
          this.StylePorcent = 'labelPorcentRed';
        } else {
          this.StylePorcent = 'labelPorcent2Red';
        }
      }
    }
  },
  computed: {
    totalPorcentaje() {
      let total = 0;
      for (const benef of this.benefData) {
        if (benef.porcentaje) {
          const parsedPorcentaje = parseFloat(benef.porcentaje);
          if (!isNaN(parsedPorcentaje)) {
            total += parsedPorcentaje;
          }
        }
      }
      return total;
    }
  }
};
</script>

<style lang="scss">
.contenedorDatos {
  padding-left: 50px;
}

.SpaceBotom {
  margin-right: 18px;
  margin-bottom: 15px
}

.SpaceBotom2 {
  margin-right: 6px;
  margin-bottom: 15px
}

.messageAlertPositive {
  color: var(--AD-Primario-2, #4F74E3);
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  line-height: 40px
}

.messageAlertNegative {
  color: #F25454;
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  line-height: 40px
}

.labelPlanes {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
  padding: 1%;
  margin-bottom: 3%;
}

.labelSubPlanes {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding-bottom: 2%;
}

.labelSubPlanes2 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;

}

.labelPorcent {
  width: 80px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
  margin-bottom: auto;
}

.labelPorcent .v-text-field__slot::after {
  content: '%';
  position: absolute;
  right: 8px;
  /* Ajusta la posición del símbolo de porcentaje */
  top: 50%;
  transform: translateY(-50%);
  color: gray;

}

.labelPorcentRed {
  width: 80px;
  height: 57px;
  background: #FFFFFF;
  border: 1px solid red;
  ;
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelPorcentRed .v-text-field__slot::after {
  content: '%';
  position: absolute;
  right: 8px;
  /* Ajusta la posición del símbolo de porcentaje */
  top: 50%;
  transform: translateY(-50%);
  color: gray
}

.labelPorcent2 {
  width: 57px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelPorcent2 .v-text-field__slot::after {
  content: '%';
  position: absolute;
  right: -8px;
  /* Ajusta la posición del símbolo de porcentaje */
  top: 50%;
  transform: translateY(-50%);
  color: gray
}

.labelPorcent2Red {
  width: 57px;
  height: 60px;
  background: #FFFFFF;
  border: 2px solid red;
  ;
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelPorcent2Red .v-text-field__slot::after {
  content: '%';
  position: absolute;
  right: -8px;
  /* Ajusta la posición del símbolo de porcentaje */
  top: 50%;
  transform: translateY(-50%);
  color: gray
}

.labelAlert {

  height: 48px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 5px;
  text-align: center;
}

.labelAlert2 {
  max-width: 75% !important;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 5px;
  text-align: center;
}

.labelField {
  width: 218px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelField2 {
  width: 113px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}


.labelParentesco {
  width: 260px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelParentesco2 {
  width: 145px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelEliminar {
  color: white;
  box-sizing: border-box;
  width: 35px;
  height: 35.1px;
  border: 0.5px solid #FFB600;
  margin-top: 15%
}

.labelRut {
  width: 260px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelRut2 {
  width: 145px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelsDatos {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelInfo {
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: #232323;
  opacity: 0.75;
  margin: auto;
}

.labelInfo3 {
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: #232323;
  opacity: 0.75;
  margin: auto;
}

.botonAgregar {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: auto;
}

.step-date {
  max-width: 200px;
  align-items: center;
  display: flex;
  margin-left: 2rem;
  justify-content: center;

  .v-select {
    max-width: 400px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}

.infoIcon {
  background-image: url("~@/assets/images/Ayuda.png");
}

.labelResumen {
  box-sizing: border-box;
  width: 100%;
  max-width: 350px;
  height: 114px;
  background: #0077A0;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin: auto;
}

.labelNamePlan {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
}

.labelPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.labelSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #FFFFFF;
}

.labelSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(255, 255, 255, 0.87);
}

.formData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v-messages__message {
  padding-bottom: 10%;
  text-align: left;
  min-width: 100px;
}

.circle-container {
  // Boton de eliminar - circulo exterior
  width: 35px;
  height: 35px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1px solid #00968F;
  margin-top: 10px;
}

.circle-button {
  // Boton de eliminar - circulo Helveticaior
  width: 29px;
  height: 29px;
  border-radius: 200px;
  background-color: var(--AD-Secundario-2, #EDB321);
  ;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: auto;
}

.circle-button v-icon {
  // Boton de eliminar - Medidas del minus
  width: 7px;
  height: 14px;
}

@media only screen and (max-width: 959.98px) {

  // Evita el mecanismo de esconder las letras del steper
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block !important;
  }
}

@media only screen and (max-width: 700.98px) {
  .v-stepper__content {
    padding: 0px 0px 16px 0px !important;
  }
}

.primary-override .v-application .primary {
  // Aqui sobreescribe el color del primary
  background-color: #FFB600 !important;
  border-color: #FFB600 !important;
}

.v-stepper__step__step {
  // Este es el step y sus medidas
  height: 61px;
  width: 61px;
  font-size: 24px;
  margin-top: 25%;
  background-size: cover;
  background-image: url("~@/assets/images/step2.png");
}

@media only screen and (max-width: 767px) {
  .v-stepper__step__step {
    width: 42px;
    height: 42px;
    font-size: 20px;
    margin-top: 40%
  }
}

@media only screen and (min-width: 767px) {
  .v-stepper__header .v-divider {
    align-self: center;
    margin: 0 -35px;
  }
}

.v-stepper__header {
  // Stepper header - contenedor de steper completo
  box-shadow: none;
  max-width: 60%;
  align-items: center;
  margin: auto;
}

.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  // boton apagado
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #8E8E8E;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.v-stepper__label {
  padding: 10%;
}

@media only screen and (max-width: 767px) {
  .v-stepper__header {
    // Stepper header - contenedor de steper completo
    box-shadow: none;
    max-width: 100%;
    align-items: center;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .v-stepper__step {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 15px; // el ancho
    position: relative;
  }
}



html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div form.v-form div div.row div.col {
  flex-basis: 0;
  flex-grow: 0;
  max-width: 100%;
}

.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 44, 119, 1);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.labelDatesdesktop {
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
  min-height: 57px;
  width: 160px;
  margin-top: 12px !important;

}

@media only screen and (max-width: 800.98px) {
  .labelDatesdesktop {
    background: #ffffff;
    border: 1.5px solid rgba(142, 142, 142, 0.2);
    box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
    border-radius: 6px;
    width: 93.5px;
    margin-top: 0px !important;
    margin-right: -17px !important;
  }
}

.div-dates {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 800.98px) {
  .div-dates {

    margin-bottom: 19px;
  }
}

.encabezado {
  color: #002C77;
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  align-items: center;
  display: flex;
}

@media (min-width: 960px) {
  .v-application .mr-md-3 {
    margin-right: 6px !important;
  }
}
</style>